import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy" />
    <section className="page-header">
      <h1>Privacy policy</h1>
    </section>    

    <section className="container flex justify-center items-stretch">
      <div>
      <p>Bij Optiek De Bruyne nemen we de bescherming van uw persoonsgegevens zeer serieus. We behandelen deze informatie vertrouwelijk en conform de geldende wetgeving (o.a. de GDPR regelgeving). We vinden het belangrijk dat u weet welke gegevens we verzamelen, hoe we deze gebruiken en hoe u deze kan beheren, opvragen en verwijderen indien gewenst. We zorgen er zowel technisch als organisatorisch voor dat wij en de leveranciers die voor ons werken zich houden aan de voorschriften betreffende de gegevensbescherming.</p>
      <h4 className="pt-8">Welke persoonsgegevens verzamelen wij en waarom?</h4>
      <p>Wanneer u bij ons een bril koopt, verzamelen en verwerken we de persoonsgegevens die u ons ter beschikking stelt (naam, adres, telefoon- en/of gsmnummer, sterkte en e-mailadres) in ons systeem. We gebruiken deze gegevens onder andere voor de productie van de glazen, om de bestelling te verwerken, om eventueel een levering af te spreken en uit te voeren en om u een goede dienst-na-verkoop te garanderen. Deze gegevens worden ook gebruikt om u op de hoogte te houden van promoties, acties, relevant nieuws, nieuwe collecties, events, etc. via onze nieuwsbrieven en andere reclamekanalen zoals brochures per post.</p>
      <h4 className="pt-8">Delen van uw persoonsgegevens.</h4>
      <p>We gebruiken uw persoonsgegevens enkel voor het uitvoeren van de verkoopsovereenkomst die u met onze onderneming heeft afgesloten en voor onze marketingactiviteiten.</p>
      <p>Voor de productie van uw brillenglazen, wordt uw sterkte meegedeeld aan de glasleverancier. We delen uw gegevens enkel en alleen met een 3e partij als dit noodzakelijk is voor het uitvoeren van de verkoopsovereenkomst, de levering of een dienst-na-verkoop.</p>
      <p>In geen enkel geval geven wij uw persoonsgegevens aan derden door zonder uw uitdrukkelijke toestemming.</p>
      <h4 className="pt-8">Uw persoonsgegevens opvragen, corrigeren of verwijderen.</h4>
      <p>U kan zich te allen tijde uitschrijven op onze nieuwsbrieven door de link te volgen onderaan de e-mailings. U kan ook een e-mail sturen naar info@optiektomdebruyne.be.</p>
      <p>U kunt ons ook te allen tijde kosteloos de bij ons opgeslagen persoonsgegevens die op u betrekking hebben opvragen. U kan ze ook te allen tijde kosteloos corrigeren of laten verwijderen. Dit doet u door een e-mail (naar info@optiektomdebruyne.be) of een brief te sturen. Bij deze aanvraag bevestigt u een kopie van uw identiteitskaart.</p>
      <h4 className="pt-8">Beveiliging</h4>
      <p>Wij bewaren uw gegevens op beveiligde servers. Om uw gegevens te beschermen tegen verlies, vernietiging, wijziging, onbevoegde toegang of verspreiding door onbevoegde personen zijn nodige organisatorische en technische maatregelen getroffen. Slechts een beperkt aantal personen hebben toegang tot uw gegevens.</p>
      </div>
    </section>
</Layout>
)

export default PrivacyPage
